import React, {useState} from 'react';
import {Block, List, ListInput} from 'framework7-react';

export default ({unit}) => {

    const [baseValue, setBaseValue] = useState(10000)
    const [selectedUnit, setSelectedUnit] = useState('')
    const units = {
        'area': [
            {
                'id': 'squaremeter',
                'factor': 1,
                'label': 'Quadratmeter',
                'unit': 'm^2'
            },
            {
                'id': 'soccerfield',
                'factor': 7140,
                'label': 'Fußballfelder',
                'unit': 'Felder'
            },
            {
                'id': 'squarekilometer',
                'factor': 1000000,
                'label': 'Quadratkilometer',
                'unit': 'km^2'
            },

            {
                'id': 'saarland',
                'factor': 2569690000,
                'label': 'Saarland',
                'unit': 'Saarlande',
            }
        ],
        'volume': [
            {
                'id': 'cubicmeter',
                'factor': 1,
                'label': 'Kubikmeter',
                'unit': 'm^3'
            },
            {
                'id': 'bathtub',
                'factor': 0.15,
                'label': 'Badewannen',
                'unit': 'Wannen'
            },
            {
                'id': 'cubicmeter',
                'factor': 32,
                'label': 'Tanklaster',
                'unit': 'Laster'
            },
            {
                'id': 'olympic-pool',
                'factor': 2500,
                'label': 'Olympiaschwimmbecken',
                'unit': 'Becken'
            }
        ],
        'height': [
            {
                'id': 'meter',
                'factor': 1,
                'label': 'Meter',
                'unit': 'm'
            },
            {
                'id': 'floors',
                'factor': 3,
                'label': 'Hochhausstockwerke',
                'unit': 'Stockwerke'
            },
            {
                'id': 'giraffe',
                'factor': 6,
                'label': 'Giraffe',
                'unit': 'Giraffen'
            },
            {
                'id': 'detached-house',
                'factor': 10,
                'label': 'Einfaminienhaus',
                'unit': 'Häuser'
            }
        ],
        'distance': [
            {
                'id': 'earth-moon',
                'factor': 382680,
                'label': 'Erde-Mond',
                'unit': 'Mal'
            }, {
                'id': 'meter',
                'factor': 0.001,
                'label': 'Meter',
                'unit': 'M'
            }, {
                'id': 'km',
                'factor': 1,
                'label': 'Kilometer',
                'unit': 'Km'
            }, {
                'id': 'lkws',
                'factor': 0.01875,
                'label': 'LKW Länge',
                'unit': 'LKWs'
            }, {
                'id': 'Äquatorlänge',
                'factor': 40075,
                'label': 'Äquatorlänge',
                'unit': 'Umrundungen'
            }
        ],
        'weight': [
            {
                'id': 'Kilogramm',
                'factor': 1,
                'label': 'Kilogramm',
                'unit': 'Kg'
            }, {
                'id': 'Tonne',
                'factor': 1000,
                'label': 'Tonne',
                'unit': 'T'
            }, {
                'id': 'Elefanten',
                'factor': 6500,
                'label': 'Elefanten',
                'unit': 'Elefanten'
            }, {
                'id': 'Blauwal',
                'factor': 200000,
                'label': 'Blauwal',
                'unit': 'Wale'
            }, {
                'id': 'Mittelklassewagen',
                'factor': 1500,
                'label': 'Mittelklassewagen',
                'unit': 'Wagen'
            }
        ],
        'speed': [
            {
                'id': 'meter-per-second',
                'factor': 0.27777777778,
                'label': 'Meter pro Sekunde',
                'unit': 'M/s'
            }, {
                'id': 'kilometers-per-hpur',
                'factor': 1,
                'label': 'Kilometer pro Stunde',
                'unit': 'Km/h'
            }, {
                'id': 'formular-1',
                'factor': 362.1,
                'label': 'Formel 1 Wagen',
                'unit': 'Km/h'
            }, {
                'id': 'Eurofighter',
                'factor': 2805,
                'label': 'Eurofighter',
                'unit': 'Km/h'
            }, {
                'id': 'knoten',
                'factor': 0.539956,
                'label': 'Knoten',
                'unit': 'kt'
            }, {
                'id': 'miles-per-hour',
                'factor': 0.6213711,
                'label': 'Milen pro Stunde',
                'unit': 'mph'
            }
        ],
    }

    const setValue = (newValue, id) => {
        setSelectedUnit(id)
        setBaseValue(newValue)
    }

    return (
        <Block>
            <List inlineLabels noHairlinesMd>

                {units[unit].map(unit => (
                    <ListInput
                        key={unit.label}
                        label={unit.label}
                        type="text"
                        value={(baseValue > 0 ? (selectedUnit === unit.id ? baseValue / unit.factor : (baseValue / unit.factor).toLocaleString()) : '')}
                        onInput={({target}) => {
                            setValue(target.value * unit.factor, unit.id)
                        }}
                        onFocus={() => setValue(0)}
                    >
                        <span slot="inner-end">{unit.unit}</span>
                    </ListInput>
                ))}
            </List>
        </Block>
    )
}