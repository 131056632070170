import React from 'react';
import {Block, Button, Col, Row} from 'framework7-react';

export default ({selectUnit}) => (
    <Block>
        <Row className="one-third">
            <Col>
                <Button fill large onClick={() => selectUnit('area')}>Fläche</Button>
            </Col>
            <Col>
                <Button fill large onClick={() => selectUnit('volume')}>Volumen</Button>
            </Col>
        </Row>

        <Row className="one-third">
            <Col>
                <Button fill large onClick={() => selectUnit('height')}>Höhe</Button>
            </Col>
            <Col>
                <Button fill large onClick={() => selectUnit('distance')}>Entfernung</Button>
            </Col>
        </Row>

        <Row className="one-third">
            <Col>
                <Button fill large onClick={() => selectUnit('weight')}>Gewicht</Button>
            </Col>
            <Col>
                <Button fill large onClick={() => selectUnit('speed')}>Geschwindigkeit</Button>
            </Col>
        </Row>
    </Block>
)