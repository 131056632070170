import React, { useState } from 'react';
import {
  Page,
  Navbar,
    Button
} from 'framework7-react';
import Unit from '../components/unit'
import UnitDetails from '../components/unit-details'


export default () => {

  const [unit, setUnit] = useState('');

  const selectUnit = (newUnit) => {
    setUnit(newUnit)

  }
  return (
    <Page name="home">

      <Navbar title={unit ? 'Wert eingeben' : 'Einheit wählen'}>{unit && <Button slot="left" onClick={()=> setUnit('')}>Zurück</Button>}</Navbar>
      {!unit && <Unit selectUnit={selectUnit}></Unit>}
      {unit && <UnitDetails unit={unit} />}

    </Page>
  )
  };