import React from 'react';
import {
    Page,
} from 'framework7-react';
export default () => {

    return (
        <Page name="home">

            Nicht gefunden

        </Page>
    )
};